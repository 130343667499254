import {Aktualita} from "../../src/interfaces/interfaces";

const aktuality : Aktualita[] = [
        {
                title: 'Pozvánka na závod ve společných skladbách:  ',
                PDFtitle: 'TRASKO CUP 2024 - rozpis.pdf',
                PDF: ''
        },
     

]

export const aktualityImage  = "";         // jmeno souboru i s příponou, který vložíš do /obrazky/akuality

export default aktuality
