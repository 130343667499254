// odkaz na generovani hashe hesla:
// https://passwordsgenerator.net/sha256-hash-generator/


const correctHashes = [
    'F1813E12AC248415DB825830729AAB511B68E7221470563ACBC6B62AA2CA3B6F',
    '87986A616D808890B7643788F2001BE6A29A939D411B11C5DE02D71A128DF20F'
]

export default correctHashes
